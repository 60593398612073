.top-navbar{
    background: rgb(230, 228, 228) ;
    height: 50px;
    width: 100%;
    color:#fff;
    position: relative;
    display: flex;
}
.top-navbar::before{
    content: "Welcome to My-New Dawn School";
    position: absolute;
    background: var(--blue2Color);
    width: 35%;
    height: 50px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    /* border-radius: 50%; */
    padding-left: 99px;
    display: block;
    color: #fff;
    padding-top: 10px;
    font-weight:500
}
.newnav-top{
    position: relative;
    display: flex;
    justify-content: space-between;
    font-family: var(--textFamily);
    font-size: 16px;
    line-height: 45px;
    letter-spacing: 0;
    font-style: normal;
    font-weight: 400;
    width: 100%;
}
.newnav-top .number{
    color: var(--blackColor);
    padding-left: 30px!important;
}
.newnav-top span{
    padding: 0px 10px 0px 3px;
}
.newnav-top .react-icon{
    color: var(--blueColor);  
}
.nav-main-btn{
    border: none;
    background: transparent;
    margin-right: 30px;
}
.nav-btn-nav{
    text-decoration: none;
    padding: 5px 10px;
    background: var(--blueColor);
    border-radius: 50px;
    color: #fff;
    text-transform: uppercase;
    font-size:16px;
    font-weight: 600;
    transition: 0.4s all ease;
}
.nav-btn-nav:hover{
    color:#fff;
    background: var(--blue2Color)
}

/*main navbar css starts here */

.navbar {
    height: 110px;
    display: flex!important;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    transition:0.5s all ease;
    width:100%;
}
.navbar.active{
    background: #fff;
    position: -webkit-sticky;
    position: fixed;
    top:0;
    width: 100%;
    height:110px;
    box-shadow: 0px 15px 25px -15px var(--blue2Color);
    z-index: 20;
}
.nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100px;
    max-width: 1500px;
    padding:0px 50px;
}

.nav-logo img{
    width:130%;
    height:100px;
}
.nav-menu{
    display: flex;
    list-style: none;
    text-align: center;    
}
.nav-links {
    text-decoration: none;
    padding: 0.5rem 1.5rem;
    height: 100%;
    border-bottom: 3px solid transparent;
    font-family: var(--textFamily);
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 600;
    color: var(--blackColor);
    transition: 0.4s all ease   
}
.nav-links:hover{
    color:var(--blue2Color);
}
.nav-item {
    line-height: 75px;
}
.nav-item .active {
    color: var(--blue2Color)!important;
    border-top:3px solid var(--blue2Color);
    padding-top:25px;
}

.nav-icon{
    display: none;
    font-size: 30px;
    cursor: pointer;
    color: var(--blackColor)
}
.dropdown-content{
    position: absolute;
    margin-top: 0px;
    background: var(--blue2Color)
}
.dropdown-content ul{
    list-style: none;
    padding: 0px;
    margin:0
}
.dropdown-content li{
    line-height: 40px;
    text-align: left;
}
.dropdown-nav{
    color: var(--blackColor);
    font-size: 17px;
    font-family: var(--textFamily);
    text-decoration: none;
    font-weight: 550;
    padding: 0px 15px;
    display: none
}
.nav-item:hover .dropdown-nav{
    display: block
}
.nav-item2 .active {
    color: #fff!important;
    border-top:none;
    padding-top:0px;
}

.popup-main{
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}
.popup{
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background: var(--blue2Color);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 30
}
.popup-inner{
    position: relative;
    padding: 32px;
    width: 50%;
    background: #fff;
    color:var(--blackColor)
}
.popup-inner .close-btn{
    position: absolute;
    top: 0px;
    right: 5px;
    color: red;
    border: none;
    background: transparent;
    font-size: 25px;
}
.popup-account{
    font-size: 18px;
    font-family: var(--textFamily);
}
.popup-account span{
    font-weight: 600;
}
.navtop-email a{
    text-decoration: none;
    color: var(--blackColor);
    transition:0.4s all ease
}
.navtop-email a:hover{
    color: rgb(58, 58, 58);
}
@media screen and (max-width: 1000px){
    .nav-icon{
        display: block;
    }
    .nav-menu{
        display: flex;
        flex-direction: column;
        width: 100%;
        border-top: 1px solid #fff;
        position: absolute;
        top: 100px;
        left: -110%;
        opacity: 1;
        transition: all 0.5s ease;
        text-align: left;
    }
    
    .nav-menu.active {
        background: var(--blue2Color);
        left: 0px;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
      
    }
    .nav-links {
        padding: 0.5rem;
        width: 100%;
        display: table;
    }
    .nav-item {
        line-height: 15px;
    }
    .nav-links:hover{
        background: rgb(190, 228, 240);
        color:#fff;
    }
    .nav-item .active {
        color: var(--blueColor)!important;
        border-top:3px solid var(--blue2Color);
        padding-top:0px;
    }
   .nav-item:hover .dropdown-nav{
        display: block
    }
    .nav-item .dropdown-content{
        height:auto;
    }
    

    /* css for the top navbar */
    .top-navbar::before{
        content: "Welcome to My-New Dawn School";
        position: absolute;
        background: var(--blue2Color);
        width: 42%;
        height: 50px;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        padding-left: 40px;
        display: block;
        color: #fff;
        padding-top: 10px;
        font-weight:500
    }
    .navtop-email{
        display: none
    }
    .nav-main-btn{
        width: 15%;
        margin-right: 30px;
    }
    .popup-inner{
        position: relative;
        padding: 20px;
        width: 70%;
    }
    .nav-logo{
        width: 30%;
        overflow-x: hidden;
    }
    .nav-logo img{
        width: 100%;
        height:80px;
    }
    .popup-account{
        font-size: 15px;
        font-family: var(--textFamily);
    }
    .popup-account span{
        font-weight: 550;
    }
}

@media screen and (max-width: 760px){
    .top-navbar{
        height: 60px;
        
    }
    .top-navbar::before{
        content: "Welcome to My-New Dawn School";
        position: absolute;
        background: var(--blue2Color);
        width: 60%;
        height: 60px;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        padding-left: 20px;
        display: block;
        color: #fff;
        padding-top: 10px;
        font-weight:500
    }
    .newnav-top {
        display: none;
    }
    .nav-main-btn{
        margin-right: 30px;
        width: 40%;
        position: relative;
        right:-10px;
    }
    .popup-inner{
        position: relative;
        padding: 20px;
        width: 90%;
    }
    .nav-logo{
        width: 30%;
        overflow-x: hidden;
    }
    .nav-logo img{
        width: 100%;
        height:80px;
    }
}