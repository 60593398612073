.contactpage-container{
    width: 100%;
    overflow: hidden;
}
.contact-address{
    background: var(--blueColor);
    color:#fff;
    font-family: var(--textFamily);
    padding: 60px 0px 10px 80px!important;
}
.contact-title{
    font-size:40px;
    font-weight: 700;
    padding-bottom: 5px;
}
.contact-text{
    font-size: 17px;
    padding-bottom: 30px;
}
.contact-icon{
    width:60px;
    height: 60px;
    border-radius: 50%;
    background: #fff;
    color:var(--blueColor);
    font-size: 13px;
    padding:15px
}
.contact-row{
    padding-bottom: 30px;
}
.contact-details{
    font-size: 17px;
}
.contact-details span{
    display: block;
    padding-bottom: 15px;
}
.contact-social-icon{
    font-size: 30px;
    text-transform: capitalize;
    font-weight: 700;
}
.contact-social-icon span{
    font-size: 15px;
    padding: 0px 15px;
    position: relative;
    top:-10px;
}
.contact-social-icon .social-icon{
    height: 40px;
    width:40px;
    border-radius: 50%;
    background: var(--blue2Color);
    padding: 10px;
}
.contact-social-icon .social-icon1{
    background:violet
}
/* .contact-form{
    position: relative;
} */
.formsuccess{
    background:linear-gradient(180deg, rgba(189, 217, 233, 0.753), rgba(179, 219, 238, 0.5)), url("https://res.cloudinary.com/hayteetech/image/upload/v1645890438/Mynewdawnschool/formsuccess_ri4z67.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width:100%;
    height: 200px;
   padding: 60px 0px 60px 0px;
   color:#fff;
}
/* css for formpage */
.formpage{
    padding: 60px 0px 30px 0px;
    color: var(--blackColor);
    font-family: var(--textFamily);
}
.form-title{
    font-size: 40px;
    font-weight: 700;
}
.formpage p{
    font-size:16px;
    font-style:italic;
}
.formpage p span{
    color:red;
}
.form-input{
    padding: 10px 0px
}
.form-input .error{
    color: red;
    font-size:14px;
}
.form-input .inputfield{
    height: 45px;
    width: 80%;
    border-radius: 50px;
    padding-left: 10px;
    background:#f6f6f6!important;
    border:1px solid var(--blue2Color);
    font-size:18px;
}
.form-input .inputfield:focus, .form-input .textareafield:focus{
    outline: none;
    border: 1px solid var(--blueColor)
}
.form-input .textareafield{
    width: 80%;
    border-radius: 50px;
    padding-top: 20px;
    padding-left: 10px;
    background:#f6f6f6!important;
    border:1px solid var(--blue2Color);
    font-size:18px;
}
.input-btn{
    border:none
}
.form-btn{
    position: relative;
    margin-top: -3px!important;
}
@media screen and (max-width: 760px){
    .contact-address{
        padding: 30px 0px 0px 5px!important;
    }
    .contact-title{
        font-size:30px;
    }
    .contact-text{
        font-size: 16px;
        padding-bottom: 15px;
    }
    .contact-icon{
        width:50px;
        height: 50px;
        border-radius: 50%;
        background: #fff;
        color:var(--blueColor);
        font-size: 13px;
        padding:12px
    }
    .contact-row{
        padding-bottom: 15px;
    }
    .contact-details{
        font-size: 15px;
    }
    .contact-details span{
        padding-bottom: 5px;
    }
    .contact-social-icon{
        font-size: 20px;
    }
    .contact-social-icon span{
        font-size: 10px;
        padding: 0px 10px;
        position: relative;
        top:-10px;
    }
    .contact-social-icon .social-icon{
        height: 40px;
        width:40px;
        border-radius: 50%;
        background: var(--blue2Color);
        padding: 10px;
    }
    .formpage{
        padding-top: 30px;
    }
    .form-title{
        font-size: 30px;
    }
    .form-input .inputfield {
        height: 45px;
        width: 100%;
    }
    .form-input .textareafield{
        width: 100%;
    }
    .form-btn{
        position: relative;
        margin-top: -3px!important;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 1000px){
    .form-btn{
        position: relative;
        margin-top: -3px!important;
        margin-bottom: 30px;
    }
}