.faq-container{
    padding-bottom: 60px;
}
.faq-page{
    padding: 30px 0px;
}
.faqs{
    width: 100%;
    /* max-width: 768px; */
    margin:0 auto;
    /* padding: 15px; */
}
.faqs .faq{
    margin: 5px 0px 10px 0px;
    padding: 15px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    border:2px solid #e4e2e2!important;
}
.faqs .faq-question {
    position: relative;
    font-size: 17px;
    font-weight: 600;
    padding-right: 80px;
    transition: all 0.4s ease;
    font-family: var(--textFamily);
    color: var(--blackColor);
}

.faqs  .faq-question::after{
    content:'';
    position: absolute;
    top: 50%;
    right: 0px;
    transform:translateY(-50%);
    width: 30px;
    height: 30px;  
    background-image: url('../../../images/arrows.PNG');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    transition: all 0.4s ease;
}
.faqs  .faq-answer{
    opacity:0;
    max-height: 0;
    overflow-y: hidden;
    transition:  all 0.4s ease;
    color: rgb(36, 35, 35);
    font-size: 16px; 
    font-family: var(--textFamily);
    font-weight: 500;
    background: #e4e2e2;
    text-align: justify;
    padding: 5px 10px;
     
}
.faqs  .open .faq-question{
    margin-bottom: 25px;
}
.faqs  .open .faq-question::after{
    transform: translateY(-50%) rotate(180deg);
}
.faqs  .open .faq-answer{
    max-height: 1000px;
    opacity: 1;
}