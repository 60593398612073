.teacherpage{
    padding:60px 0px 40px 0px;
}
.director-profile{
    padding-top: 30px;
}
.director-img{
    width: 90%;
    height: 300px;
    background: rgb(218, 216, 216);
    border-top-right-radius: 80px;
    border-bottom-left-radius: 80px;
    margin: auto;
    margin-bottom: 20px;
}
.director-img img{
    width: 100%;
    height: 250px;
    object-fit: contain;
    margin: auto;
}
.director-content{
    font-family: var(--textFamily);
    color: var(--blackColor);
    line-height: 33px;
    text-align: justify;
    font-size: 18px;
}
.director-content span{
    color: var(--blueColor);
    font-size: 20px;
    font-weight: 600
}