.about-container{
    padding-top:40px;
}
.about-text{
    text-align: justify;
    line-height: 30px;
    font-family: var(--textFamily);
    color: var(--blackColor);
    font-size: 16px;
    font-weight: 550;
    padding-bottom: 20px;

}
.about-text span{
    font-weight: 700;
}
/* css for card */
.about-card-container{
    padding-bottom: 50px;
}
.card-container-text{
    text-transform: uppercase;
    font-size:17px;
    font-weight:700;
    padding:20px 0px 10px 0px;
}
.about-card{
    width: 100%;
    height: 250px;
    position:relative;
    margin-bottom: 40px;
    border-radius: 10%;
}
.about-card::before{
    content:"";
    position: absolute;
    bottom:0;
    width:0;
    height:0;
    border-style:solid;
    border-width:50px 50px 0 50px;
    border-color:var(--blue2Color) transparent transparent transparent;
    left:50%;
    transform:translateX(-50%) translateY(100%);
}
.about-card img{
    width: 100%;
    height: 200px;
    /* object-fit:contain; */
    position: relative;
}
.card-text{
    background: linear-gradient(180deg, rgba(61, 179, 247, 0.443), rgba(114, 179, 233, 0.443));
    color:#fff;
    width: 100%;
    text-align: center;
    font-size:16px;
    text-transform: uppercase;
    font-weight: 900;
    padding: 13px 0px;
}
.card-nav{
    text-decoration: none;
    color:#fff;
}
.card-viewmore{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    background: var(--blue2Color);
    padding: 10px 15px;
    font-weight:700;
    font-size:17px;
    text-transform: uppercase;
    display: none;
    color:#fff;
    transition: 0.4s all ease;
    border-radius: 50px;
}

.about-card:hover .card-viewmore{
    display: block;
}
.about-card:hover{
background: linear-gradient(180deg, rgba(2, 2, 2, 0.938), rgba(0, 0, 0, 0.911))!important;
}
.about-card:hover img{
    opacity:0.2;
}