.otherpage-background{
    background:linear-gradient(180deg, rgba(2, 2, 2, 0.89), rgba(0, 0, 0, 0.863)),url("https://res.cloudinary.com/hayteetech/image/upload/v1645890584/Mynewdawnschool/SCHOOL_mx525z.jpg");
    width: 100%;
    height: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center ;
    object-fit: contain;
}
.otherpage-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 70px;
    color:#fff;
    font-family: var(--textFamily);
}
.otherpage-title{
    font-size: 34px;
    font-weight: 700
}
.otherpage-subtitle{
    background: linear-gradient(180deg, rgba(61, 179, 247, 0.443), rgba(114, 179, 233, 0.443));
    padding: 10px 15px;
    border-radius: 50px;
    font-size: 15px;
    font-weight: 600;
    
}
.otherpage-icon{
    color: var(--blackColor);
    background:#fff;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    padding: 7px;
}
.otherpage-subtitle span{
    padding:0px 5px
} 
.otherpage-link{
    text-decoration: none;
    color:#fff;
    padding-left: 5px;
    transition: 0.4s all ease;
}
.otherpage-link:hover{
    color:var(--blue2Color);
}
.small-screen{
    display: none;
}

@media screen and (max-width: 760px){
    .big-screen{
        display: none;
    }
    .otherpage-container{
        padding-top: 30px;
    }
    .otherpage-title{
        font-size: 25px;
        font-weight: 700
    }
    .otherpage-subtitle{
        margin-top: 30px;
        width: 70%;
    }
}

@media screen and (max-width: 1000px){
    .small-screen{
        display: none;
    }
}
@media screen and (max-width:760px){
    .otherpage-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 70px;
        color:#fff;
        font-family: var(--textFamily);
        position: relative;
    }
    .otherpage-title{
        font-size: 34px;
        font-weight: 700;
        text-align: center;
       
    }
}