.school-songpage{
    padding:60px 0px 40px 0px;
}
.school-songpage img{
    width: 100%;
}
.schoolsong{
    font-size:25px;
    font-weight:700;
    padding:10px 0px 30px 0px;
    color: var(--blackColor);
    font-family: var(--textFamily);
}
.schoolanthem{
    font-family: var(--textFamily);
    line-height: 30px;
    font-size:16px;
    font-weight: 600;
    padding-bottom: 10px;
}
.school-song-audio{
    padding: 15px 0px;
}