
.footerpage{
    background: var(--blue2Color);
    padding:40px 0px;
    color:var(--blackColor);
    font-family: var(--textFamily);
    overflow:hidden;
}
.footerpage .footer-title{
    font-size: 16px;
    text-transform: uppercase;
    padding:20px 0px;
    font-weight: bold;
    color:var(--blueColor)
}
.footerpage .footer-text{
    font-size: 16px;
    padding: 10px 0px;
    font-weight:550;
}
.footer-textcontactinfo{
    font-size: 15px!important;
}
.footer-textcontactinfo a{
    color: var(--blackColor);
    text-decoration: none;
    transition: 0.4s all ease;
}
.footer-textcontactinfo a:hover{
    color: var(--blueColor)
}
.footer-text span{
    color: var(--blueColor);
    font-weight: bold;
}
.footerpage ul{
    list-style: none;
    margin:0;
    padding:0
}
.footerpage ul li:not(:first-child){
    padding: 5px 0px;
}
.footer-nav{
    color:var(--blackColor);
    text-decoration: none;
    transition: 0.4s all ease
}
.footer-nav:hover{
    color: var(--blueColor)
}

.footer-policy{
    background: var(--blueColor);
    color:#fff;
    padding:35px 0px 30px 0px;
    margin-top: 0px;
}
.footer-policy-text{
    display: flex;
    justify-content: space-between;
}
.footer-policy-text p{
    font-size: 14px;
}
.footer-policy-text p a{
    color: var(--blue2Color);
    text-decoration: none;
    transition: 0.4s all ease;
}
.footer-policy-text p a:hover{
    color: #fff
}