.blogpage{
    padding: 60px 0px
}

.blogpost-container{
    /* margin: 30px 0px */
}
.blogpost-container img{
    width: 100%;
    height: 250px;
    object-fit: cover;
    position: relative;
}
.blogpost-banner{
    background: linear-gradient(180deg, rgba(26, 29, 216, 0.959), rgba(33, 30, 207, 0.884));
    color:#fff;
    max-width:60px;
    position: relative;
    padding:5px 10px;
    top:-220px
}
.blogpost-content{
    background: #fff;
    height: 250px;
    padding: 10px;
}
.blogpost-content:hover{
    background: var(--blueColor);
    color: #fff;
}
.blogpost-content:hover .blogpost-nav{
    color:#fff;
}
.blogpost-nav{
    text-decoration: none;
    color: var(--blackColor)
}
.blogpost-nav:hover{
    color:#fff;
}
.blogpost-title{
    font-size: 25px;
    font-family: var(--textFamily);
    font-weight: 700;
    text-transform: capitalize;
    padding-bottom: 20px;
}
.blogpost-text{
    font-size: 16px;
    font-weight:600;
    line-height: 30px;
    padding-bottom: 30px;
    text-align: justify;
}
.blogpostdate{
    color:rgb(158, 158, 158);
    font-size: 15px;
}
.blogpostdate span{
    padding-right: 7px;
}
.blogpostdate span:last-child{
    padding-left: 40px;
}
/* css for react-paginate */
.paginationBttns{
    width: 80%;
    height: 30px;
    list-style: none;
    display: flex;
    justify-content: center;
}
.paginationBttns a{
    padding: 5px 8px;
    margin: 8px;
    border-radius: 5px;
    border: 1px solid var(--blueColor);
    color: var(--blueColor)!important;
    cursor: pointer;
    text-decoration: none;
    transition: 0.4s all ease;
}
.paginationBttns a:hover{
    color: #fff!important;
    background: var(--blueColor)
}
.paginationActive a{
    color: #fff!important;
    background: var(--blueColor)
}

/* css for single post */
.singlepost-container{
    padding: 60px 0px;
}
.singlepost-date{
    border: 3px solid var(--blue2Color);
    width: 75%;
    text-align: center;
    height: 90px;
    color: var(--blackColor);
    font-family: var(--textFamily);
    font-weight: 600
}
.singlepost-date h1{
    color: var(--blueColor)
}
.singlepost-title{
    font-size: 40px;
    font-family: var(--textFamily);
    font-weight: 600;
    text-transform: capitalize;
    padding-bottom: 10px;
    color: var(--blackColor)
}
.singlepost-others{
    display: flex;
    color: rgb(158, 158, 158);
}
.singlepost-others p{
    background: var(--blueColor);
    color:#fff;
    font-size: 13px;
    padding: 5px 10px;
    font-weight: 550;
    margin-right: 10px;
}
.singlepost-others span{
    padding-left: 40px;
}
.singlepost-text{
    line-height: 35px;
    font-family: var(--textFamily);
    color: var(--blackColor);
    text-align: justify;
}
.singlepost-img{
    width: 60%;
    overflow: hidden;
    height: fit-content;
    margin-bottom: 30px;
    border: 4mm ridge var(--blue2Color);
    height: 500px;
    max-height: 500px;
    background: #bce2ff56;
}
.singlepost-img img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    mix-blend-mode: multiply;
    filter: contrast(1)
}


/* css for sidebar starts here */
.sidebar-header{
    font-size: 18px;
    font-family: var(--textFamily);
    padding: 10px 0px 20px 0px;
    font-weight: 600
}
.sidebar-container{
    border: 2px solid #e4e2e2;
}
.sidebar-page{
    border: 1px solid var(--blue2Color);
    padding: 5px;
    font-family: var(--textFamily);
    background: #fff;
    transition:0.4s all ease
}
.sidebar-page h5{
    color: var(--blueColor);
    font-weight: 600;
    text-transform: capitalize;
    font-size: 16px;
}
.sidebar-page p{
    font-size: 14px;
    color: #c7c4c4
}
.sidebar-nav{
    text-decoration: none;
}
.sidebar-nav:hover{
    color: #fff;
}
.sidebar-page:hover{
    background:var(--blue2Color);
    color: #fff;
}
.sidebar-page:hover h5{
    color: #fff
}

.loading{
    display: flex;
    justify-content: center;
}
.loading::after{
    content: "";
    width: 50px;
    height: 50px;
    border: 10px solid #dddddd;
    border-top-color: var(--blue2Color);
    border-bottom-color: var(--blueColor);
    border-radius: 50%;
    animation: loading 1s linear infinite!important;
}
@keyframes loading {
    to{
        transform:rotate(1turn);
    }
}

@media screen and (max-width: 760px) {
    .singlepost-title{
        font-size: 30px;
    }
    .singlepost-img{
        width: 80%;
        overflow: hidden;
        padding: 5px 0px;
        height: auto;
        margin-bottom: 0;
    }
    .singlepost-img img{
        width: 100%;
        height: 300px;
        object-fit: contain
    }
}