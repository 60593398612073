.admission-page{
    padding: 60px 0px 50px 0px;

}
.admission-text{
    text-align: justify;
    font-size:17px;
    font-weight: 600;
    font-family: var(--textFamily);
    color: var(--blackColor);
    line-height: 30px;
    padding: 20px 0px;
}
.admission-contact{
    border: 2px solid #e4e2e2;
    box-shadow: 5px 0px 5px 5px #e4e2e2;
    margin-top: 40px;
}
.admission-title{
    background: var(--blueColor);
    align-items: center;
    text-align: center;
    color: #fff;
    padding: 15px 0px;
    font-weight: 600;
    font-size: 18px;
    font-family: var(--textFamily);
}
.admission-col:not(:last-child){
    border-right: 2px solid var(--blueColor);
}
.admission-column{
    padding: 30px 15px;
    font-family: var(--textFamily);
}
.admission-column-title{
    font-size: 17px;
    font-weight: 600;
    padding-bottom: 10px;
}
.admission-column span{
    display:block;
    padding: 5px;
    font-size:16px;
    font-weight: 600
}
.admission-img{
    position: relative;
    width:100%;
}
.admission-img img{
    width:100%;
    height: 400px;
    cursor: pointer;
    display: block;
}
.overlay{
    position: absolute;
    background: linear-gradient(180deg, rgba(2, 2, 2, 0.89), rgba(0, 0, 0, 0.863));
    width: 100%;
    height:400px;
    top:0px;
    bottom: 0px;
    left:0px;
    right:0px;
    transition: 0.4s all ease;
    opacity: 0;
}
.admission-img button{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    border: none;
    padding: 30px 10px;
    background: var(--blueColor);
    color:#fff;
    font-family: var(--textFamily);
    font-size:17px;
}
.admission-img:hover  .overlay{
    opacity: 1;
}
.admission-form span{
    font-weight:600;
    border-bottom: 1px solid var(--blueColor);
}
.admission-form h6{
    font-style: italic;
    font-family: var(--textFamily);
    color: var(--blackColor);
    font-size:15px;
    padding-bottom: 30px;
}
/* .admission-form h1{
    font-size:50px;
    font-family: var(--textFamily);
    color: var(--blackColor);
    text-align: center;
} */